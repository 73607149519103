//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { Message } from "semantic-ui-react";
import { Organization as ApiOrganization, DeploymentList as ApiDeploymentList } from "../../api/lib";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { Routes } from "../../routes";
import { useDashboardContext } from "../DashboardContextProvider";
import { useTranslation } from "react-i18next";
import { FREE_TRIAL_PERIOD_DAYS, PERPETUAL_FREE_TRIAL_PERIOD_DAYS } from "../../constants";

interface IFreeDeploymentAdViewArgs {
  organization: ApiOrganization;
  deployments?: ApiDeploymentList;
}

export const FreeDeploymentAdView = ({ ...args }: IFreeDeploymentAdViewArgs) => {
  const { t } = useTranslation();
  const { isPerpetualFreeTrialAvailable } = useDashboardContext();
  const deploymentList = args.deployments || {};

  const deployments = deploymentList.items || [];
  const has_deployment = !_.isEmpty(deployments);
  const tier = args.organization.tier || {};
  const is_free_tier = tier.id == "free";
  const billingRoute = Routes.dashboard_organization_billingWithId(args.organization.id || "");
  if (!has_deployment && is_free_tier) {
    return (
      <Message info size="huge">
        <p className="para">
          {t("freeDeploymentAd.description", {
            days: isPerpetualFreeTrialAvailable ? PERPETUAL_FREE_TRIAL_PERIOD_DAYS : FREE_TRIAL_PERIOD_DAYS,
          })}
        </p>
        <NavLink to={billingRoute}>{t("freeDeploymentAd.link")}</NavLink>
      </Message>
    );
  }
  return null;
};
