//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import styled from "@emotion/styled";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Divider, Grid, Icon, Message, Popup, Segment, Statistic } from "semantic-ui-react";
import {
  BackupList as ApiBackupList,
  BackupPolicyList as ApiBackupPolicyList,
  Deployment as ApiDeployment,
  Deployment_CertificateSpec as ApiDeployment_CertificateSpec,
  Deployment_Status as ApiDeployment_Status,
} from "../../api/lib";
import {
  ContentGroup,
  ContentKeyValuePair,
  ContentKeyValuePairs,
  ContentKV_Key,
  ContentKV_Value,
  EndpointContainer,
  RootPassword,
  Section,
  SectionContent,
  SectionHead,
  SectionHeader,
  StatisticsContentGroup,
  StyledStatsSegment,
} from "../../ui/lib";
import { ResourceType } from "../../util/PermissionCache";
import { IWithRefreshProps } from "../../util/WithRefresh";
import { BackupSummary } from "../backup/BackupSummary";
import CACertificate from "./CACertificate";
import ConnectDriverInstructions from "./ConnectDriverInstructions";
import { DeploymentStatusView } from "./DeploymentStatusView";
import IPAllowlist from "./IPAllowlist";
import Location from "./Location";
import AccordionView from "../Accordion/Accordion";
import { AppLinkButton } from "../../ui/_link";
import PasswordSettingModalView, { IPasswordSettingArgForOverviewComponent, IPasswordSettingsModalViewExternalArgs } from "./PasswordSettingsModalView";
import { IAccordionItemViewArgs } from "../Accordion/types";
import copy from "copy-to-clipboard";
import { RegionProviders, REGIONS } from "../private-network/types";
import { useDeploymentPermissions } from "./useDeploymentPermissions";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { useDashboardContext } from "../DashboardContextProvider";
import { Routes } from "../../routes";
import { useHistory } from "react-router-dom";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import { UseAutoLoginSwitch } from "./UseAutoLoginSwitch";

export const CustomImageTag = styled("div")`
  font-size: 60%;
  font-style: italic;
`;

interface IAccessViewArgs extends IWithRefreshProps, RouteComponentProps, IPasswordSettingsModalViewExternalArgs {
  isDeploymentPaused: boolean;
  ipallowlistId?: string;
  certificates?: ApiDeployment_CertificateSpec;
  status?: ApiDeployment_Status;
  copiedRootPassword: boolean;
  loadingRootPassword: boolean;
  rootPassword?: string;
  onCopyRootPassword: () => void;
  showRootPassword: boolean;
  onShowRootPasswordToggle: () => void;
}

const SecondaryURLComponent = ({ url }: { url: string }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    copy(url);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  return (
    <div className="">
      <span>
        {url}
        {"  "} <Button basic icon={copied ? "check" : "copy"} size="mini" onClick={handleCopy} />
      </span>
    </div>
  );
};

const AccessView = ({ ...args }: IAccessViewArgs) => {
  const { deployment } = useDeploymentStore();
  const { status = {}, is_paused: isDeploymentPaused } = deployment;
  const isBootstrapped = !!(status && status.endpoint && status.bootstrapped);
  const isConvertedToPrivateEndpoint = !!status.private_endpoint;
  const { private_endpoint_only: onlyPrivateEndpoint = false, endpoint_private_endpoint: privateNetworkEndpoint = "", endpoint, endpoint_default } = status;
  const { isGetRootPasswordAllowed, canUpdateRootPasswordRotationSchedule } = useDeploymentPermissions();

  return (
    <ContentGroup>
      {!onlyPrivateEndpoint && (
        <>
          <ContentKeyValuePairs>
            <ContentKeyValuePair>
              <ContentKV_Key>
                <Flex alignItems="baseline" gap={1}>
                  <Text>Database UI</Text>
                  <Popup
                    position="top center"
                    trigger={<Icon name="info circle" />}
                    content={
                      <div>
                        <p className="para">URL to access the database UI of your ArangoDB deployment.</p>
                        <p className="para">You will be automatically logged in using your ArangoGraph credentials if automatic database login is enabled.</p>
                      </div>
                    }
                  />
                </Flex>
              </ContentKV_Key>
              <ContentKV_Value>
                <EndpointContainer>
                  {!isBootstrapped && "-"}
                  {isBootstrapped && !isDeploymentPaused && (
                    <a href={endpoint_default} target="_blank" rel="noreferrer">
                      {endpoint_default} <Icon name="external alternate" />{" "}
                    </a>
                  )}
                  {isBootstrapped && isDeploymentPaused && endpoint_default}
                </EndpointContainer>
              </ContentKV_Value>
            </ContentKeyValuePair>
          </ContentKeyValuePairs>
          <ContentKeyValuePairs>
            <ContentKeyValuePair>
              <ContentKV_Key>
                <Flex alignItems="baseline" gap={1}>
                  <Text>Application Endpoint</Text>
                  <Popup
                    position="top center"
                    trigger={<Icon name="info circle" />}
                    content={
                      <div>
                        <p className="para">
                          URL to access your ArangoDB deployment from an application written in one ArangoDB's SDKs, via tools, or via REST API.
                        </p>
                        <p className="para">
                          You will need to have created a username and password within the database UI to authenticate against this endpoint, or use the root
                          credentials provided.
                        </p>
                      </div>
                    }
                  />
                </Flex>
              </ContentKV_Key>
              <ContentKV_Value>
                {!isBootstrapped && "-"}
                {isBootstrapped && <>{endpoint}</>}
              </ContentKV_Value>
            </ContentKeyValuePair>
          </ContentKeyValuePairs>
        </>
      )}
      {isConvertedToPrivateEndpoint && (
        <ContentKeyValuePairs>
          <ContentKeyValuePair>
            <ContentKV_Key>
              <span>
                <Icon name="shield alternate" color="grey" /> Private Endpoint
              </span>
              <Popup
                trigger={<Icon name="info circle" />}
                content={
                  <div>
                    This URL represents the private endpoint.
                    <Divider />
                    <StyledBorderlessMessage warning>
                      <Icon name="info circle" color="red" />
                      The public endpoint will be removed 2 hour after the private endpoint has been provisioned.
                    </StyledBorderlessMessage>
                  </div>
                }
              />
            </ContentKV_Key>
            <ContentKV_Value>
              <EndpointContainer>
                {!isBootstrapped && "-"}
                {isBootstrapped && !isDeploymentPaused && <SecondaryURLComponent url={privateNetworkEndpoint} />}
                {isBootstrapped && isDeploymentPaused && endpoint_default}
              </EndpointContainer>
            </ContentKV_Value>
          </ContentKeyValuePair>
        </ContentKeyValuePairs>
      )}
      <ContentKeyValuePairs>
        <ContentKeyValuePair>
          <ContentKV_Key>
            <Flex alignItems="baseline" gap={1}>
              <Text as="span">Root password </Text>
              <Popup
                position="top center"
                trigger={<Icon name="info circle" />}
                content={
                  <div>
                    <p className="para">
                      Password of the user with username <code>root</code>.
                    </p>
                    <p className="para">This user has administration level access in the database UI of deployment.</p>
                    <p className="para">It is highly recommended to create additional users and not use the root user for every day operations.</p>
                  </div>
                }
              />
            </Flex>
          </ContentKV_Key>
          <ContentKV_Value>
            <RootPassword className="mask-data">{args.showRootPassword && args.rootPassword ? args.rootPassword : "*****"}</RootPassword>&nbsp;
            <Button
              basic
              disabled={!isGetRootPasswordAllowed}
              icon={args.showRootPassword && args.rootPassword ? "eye slash" : "eye"}
              size="mini"
              onClick={args.onShowRootPasswordToggle}
            />
            <Button
              basic
              disabled={!isGetRootPasswordAllowed}
              icon={args.copiedRootPassword && !args.loadingRootPassword ? "checkmark" : "copy"}
              size="mini"
              onClick={args.onCopyRootPassword}
            />
            {canUpdateRootPasswordRotationSchedule && (
              <Button
                basic
                icon={<Icon name="setting" color={args.isPasswordRotationScheduleEnabled ? "black" : "red"} />}
                size="mini"
                onClick={args.onOpenPasswordSettingsModal}
              />
            )}
          </ContentKV_Value>
        </ContentKeyValuePair>
        {args.loadingRootPassword && <Spinner size="md" color="green.600" />}
      </ContentKeyValuePairs>

      <UseAutoLoginSwitch />

      <ContentKeyValuePairs>
        <ContentKeyValuePair>
          <ContentKV_Key>CA Certificate</ContentKV_Key>
          <ContentKV_Value>
            <CACertificate {...args} caCertificateId={args.certificates ? args.certificates.ca_certificate_id : undefined} />
          </ContentKV_Value>
        </ContentKeyValuePair>
      </ContentKeyValuePairs>
      <ContentKeyValuePairs>
        <ContentKeyValuePair>
          <ContentKV_Key>IP allowlist</ContentKV_Key>
          <ContentKV_Value>
            <IPAllowlist {...args} ipallowlistId={args.ipallowlistId} />
          </ContentKV_Value>
        </ContentKeyValuePair>
      </ContentKeyValuePairs>
    </ContentGroup>
  );
};

const StyledOverviewSegment = styled(Segment)`
  __margin-bottom: 24px !important;
`;

const StyledLocationContainer = styled("div")`
  h2 {
    margin-bottom: 8px;
  }
  h3 {
    margin-top: 0;
    margin-bottom: 40px;
  }
  .logo {
    margin-right: 0;
  }
`;

const StyledActionsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  .button {
    margin-bottom: 16px;
  }
`;

const StyledBorderlessMessage = styled(Message)`
  border: 0px !important;
  box-shadow: none !important;
  padding: 0 !important;
`;

export interface IOverviewViewArgs extends IAccessViewArgs, IPasswordSettingArgForOverviewComponent {
  isDeploymentPaused: boolean;
  isPlatformAuthenticationEnabled?: boolean;
  hasPrepaidDeploymentExpired?: boolean;
  isPrepaid?: boolean;
  regionId?: string;
  deployment_id: string;
  deployment_url: string;
  provider: RegionProviders;
  deployment: ApiDeployment;
  onResumeDeployment: () => void;
}

export const OverviewView = ({ ...args }: IOverviewViewArgs) => {
  const history = useHistory();
  const { isFreeTier } = useDashboardContext();
  const has_deployment_get = args.hasPermissionByUrl && args.hasPermissionByUrl(args.deployment_url || "", ResourceType.Deployment, "data.deployment.get");
  const has_deployment_resume =
    args.hasPermissionByUrl && args.hasPermissionByUrl(args.deployment_url || "", ResourceType.Deployment, "data.deployment.resume");
  const endpointAvailable = args.status && args.status.endpoint && args.status.bootstrapped;
  const { isPrepaid } = args;
  const [openPasswordSettings, updatePasswordSettingsModalState] = useState(false);
  const [isPasswordRotationScheduleEnabled, updatePasswordRotationScheduleState] = useState(args.isPasswordRotationScheduleEnabled);
  const { status = {} } = args;
  const isConvertedToPrivateEndpoint = !!status.private_endpoint;
  const isPrivateEndpointOnly = !!status.private_endpoint_only;
  const privateEndpoint = status.endpoint_private_endpoint || "";
  const [copied, setCopied] = useState(false);
  const { canUpdateRootPasswordRotationSchedule } = useDeploymentPermissions();
  const { deployment } = useDeploymentStore();

  const onOpenEndpoint = () => {
    const status = deployment.status || {};
    window.open(status.endpoint_default);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);
  const accordionConfig: Array<IAccordionItemViewArgs> = [
    {
      index: 0,
      content: (
        <div>
          <div data-testid="lastPasswordRotationDate">
            {isPasswordRotationScheduleEnabled && !!args.passwordLastRotatedDate && (
              <>
                The password of the root user was last rotated automatically
                <b> {moment(args.passwordLastRotatedDate).fromNow()}</b>.
              </>
            )}
            {isPasswordRotationScheduleEnabled && !args.passwordLastRotatedDate && <>The password of the root user has not yet been automatically rotated.</>}
            <Divider hidden />
          </div>
          <p className="para">
            The root password is the password for the user with name <b>root</b> inside your deployment. This user has administration level access in the
            deployment.
          </p>{" "}
          {!isPasswordRotationScheduleEnabled && canUpdateRootPasswordRotationSchedule && (
            <div>
              To enable automatic password rotation for the root user{" "}
              <AppLinkButton
                testID="passwordRotationModalAction"
                label="click here"
                handleClick={() => {
                  updatePasswordSettingsModalState(true);
                }}
              />
            </div>
          )}
          <Divider />
          <StyledBorderlessMessage warning>
            <Icon name="info circle" color="red" />
            It is highly recommended to create additional users and not use the root user for every day operations.
          </StyledBorderlessMessage>
        </div>
      ),
      label: "Root password",
      rightContent: !isPasswordRotationScheduleEnabled ? <Icon name="info circle" color="red" /> : null,
      testID: "rootPasswordAccordion",
    },
  ];

  if (isConvertedToPrivateEndpoint) {
    accordionConfig.push({
      index: 1,
      content: (
        <div>
          <p className="para">
            A private endpoint is not access-able over the internet, however needs a secure connection from within your
            {args.provider === REGIONS.aws ? " account" : " subscription"}.
          </p>
          <p className="para">
            When the private endpoint connection has been established you can access your deployment from within the connected
            {args.provider === REGIONS.aws ? " VPC" : " vNet"}
          </p>
          <Divider />
          <StyledBorderlessMessage warning>
            <p className="para">
              <Icon name="info circle" color="red" /> We strongly recommend to add at least 1 alternate DNS name to the private endpoint connection, so a secure
              TLS connection can be setup without accepting untrusted names.
            </p>
            <p className="para">The self-signed certificate served on port 18529 will contain those additional DNS names.</p>
            <p className="para">The public endpoint will be removed 2 hour after the private endpoint has been provisioned.</p>
          </StyledBorderlessMessage>
        </div>
      ),
      label: "Private endpoint",
      testID: "privateEndpointAccordion",
    });
  }

  return (
    <StyledOverviewSegment>
      <Grid columns="16">
        <Grid.Row>
          <Grid.Column width="2" textAlign="right">
            <StyledLocationContainer>
              <Location {...args} showProvider={false} showRegion={false} showProviderIcon />
            </StyledLocationContainer>
          </Grid.Column>
          <Grid.Column width="5">
            <StyledLocationContainer>
              <h2 className="heading-2">
                <Location {...args} showProvider showProviderIcon={false} showRegion={false} />
              </h2>
              <h3 className="heading-3">
                <Location {...args} showProvider={false} showRegion />
              </h3>
            </StyledLocationContainer>
            <StyledActionsContainer>
              {args.isDeploymentPaused && !isPrepaid && (
                <Button
                  primary
                  onClick={args.onResumeDeployment}
                  size="medium"
                  content="Resume deployment"
                  icon="play"
                  labelPosition="right"
                  disabled={!has_deployment_resume}
                />
              )}
              {!args.isDeploymentPaused &&
                (!isPrivateEndpointOnly ? (
                  <Button
                    primary
                    onClick={onOpenEndpoint}
                    size="medium"
                    content="Open database UI"
                    icon="database"
                    labelPosition="right"
                    disabled={!endpointAvailable}
                  />
                ) : (
                  <Button
                    primary
                    onClick={() => {
                      copy(privateEndpoint);
                      setCopied(true);
                    }}
                    size="medium"
                    content="Copy endpoint"
                    icon={copied ? "check" : "database"}
                    labelPosition="right"
                    disabled={!endpointAvailable}
                  />
                ))}
              {has_deployment_get && <ConnectDriverInstructions {...args} disabled={!endpointAvailable} deploymentId={args.deployment_id || ""} />}
              {isFreeTier && (
                <Button icon="unlock alternate" labelPosition="right" content="Unlock all features" onClick={() => history.push(Routes.dashboard_pricing)} />
              )}
            </StyledActionsContainer>
          </Grid.Column>
          <Grid.Column width="6">
            <AccessView
              {...args}
              onOpenPasswordSettingsModal={() => updatePasswordSettingsModalState(true)}
              isPasswordRotationScheduleEnabled={isPasswordRotationScheduleEnabled}
            />
          </Grid.Column>
          <Grid.Column width="3">
            <AccordionView accordionConfig={accordionConfig} />
            {!!openPasswordSettings && (
              <PasswordSettingModalView
                isPasswordRotationScheduleEnabled={isPasswordRotationScheduleEnabled}
                deploymentID={args.deployment_id}
                onClose={() => updatePasswordSettingsModalState(false)}
                onRootPasswordRotationStateUpdate={(enabled: boolean) => {
                  updatePasswordRotationScheduleState(enabled);
                }}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </StyledOverviewSegment>
  );
};

export const StyledStatisticGroup = styled(Statistic.Group)`
  display: flex;
  justify-content: space-evenly;
`;

export interface IOverviewStatsViewArgs extends Partial<IOverviewViewArgs> {
  deployment: ApiDeployment;
  backups?: ApiBackupList;
  lastGoodBackups?: ApiBackupList;
  backupPolicies?: ApiBackupPolicyList;
  isDeploymentPaused: boolean;
  version?: string;
  custom_image?: string;
}

export const OverviewStatsView = ({ ...args }: IOverviewStatsViewArgs) => {
  const lastGoodBackupItems = (args.lastGoodBackups || {}).items || [];
  const lastSafeBackup = _.first(lastGoodBackupItems.map((x) => x.created_at));
  const nextBackup = BackupSummary.getNextBackup(args.backupPolicies);
  const nextBackupValue = nextBackup ? (args.isDeploymentPaused ? "Paused" : moment(nextBackup).fromNow()) : "None";
  const expiration = args.deployment.expiration || {};
  const showDeletedAt = !!args.deployment.is_deleted;
  const showExpiresAt = !showDeletedAt && !!expiration.expires_at;
  const { isPrepaid } = args;
  const { selectedOrganization } = useDashboardContext();
  const isFreeTier = selectedOrganization.tier && selectedOrganization.tier.id == "free";

  const expiryString = expiration.expires_at ? `${moment(expiration.expires_at).fromNow()}` : "";

  if (isFreeTier) {
    return null;
  }

  return (
    <Section>
      <SectionHead>
        <SectionHeader title="Status summary" />
      </SectionHead>
      <SectionContent>
        <StyledStatsSegment>
          <StatisticsContentGroup>
            <StyledStatisticGroup>
              <Statistic size="mini">
                <Statistic.Label>Status</Statistic.Label>
                <Statistic.Value>
                  <DeploymentStatusView icons={false} deployment={args.deployment} status={args.deployment.status} />
                </Statistic.Value>
              </Statistic>

              {!isPrepaid && showExpiresAt && (
                <Statistic size="mini">
                  <Statistic.Label>
                    Expires
                    {expiration.reason && <Popup content={expiration.reason} trigger={<Icon name="info circle" className="secondary-text" />} />}
                  </Statistic.Label>
                  <Statistic.Value>{expiration.expires_at ? expiryString : "-"}</Statistic.Value>
                </Statistic>
              )}

              <Statistic size="mini">
                <Statistic.Label>
                  <Icon name="calendar check" className="primary-text" /> Last safe backup
                </Statistic.Label>
                <Statistic.Value>{lastSafeBackup ? moment(lastSafeBackup).fromNow() : "None"}</Statistic.Value>
              </Statistic>

              <Statistic size="mini">
                <Statistic.Label>
                  <Icon name="calendar check" className="secondary-text" /> Next backup
                </Statistic.Label>
                <Statistic.Value>{nextBackupValue}</Statistic.Value>
              </Statistic>

              <Statistic size="mini">
                <Statistic.Label>Created</Statistic.Label>
                <Statistic.Value>{args.deployment.created_at ? moment(args.deployment.created_at).fromNow() : "-"}</Statistic.Value>
              </Statistic>

              {isPrepaid && (
                <Statistic size="mini">
                  <Statistic.Label>Contract Ends</Statistic.Label>
                  <Statistic.Value>{moment(args.deployment.prepaid_deployment_ends_at).fromNow()}</Statistic.Value>
                </Statistic>
              )}

              {showDeletedAt && (
                <Statistic size="mini">
                  <Statistic.Label>Deleted</Statistic.Label>
                  <Statistic.Value>{args.deployment.deleted_at ? moment(args.deployment.deleted_at).fromNow() : "-"}</Statistic.Value>
                </Statistic>
              )}

              <Statistic size="mini">
                <Statistic.Label>Version</Statistic.Label>
                <Statistic.Value>
                  {args.version}
                  {!!args.custom_image && <CustomImageTag>{args.custom_image}</CustomImageTag>}
                </Statistic.Value>
              </Statistic>
            </StyledStatisticGroup>
          </StatisticsContentGroup>
        </StyledStatsSegment>
      </SectionContent>
    </Section>
  );
};
