//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

export const DefaultRegionID = "gcp-us-central1";
export const DefaultAWSRegionID = "aws-us-east-2";

export const ModelOneshard = "oneshard";
export const ModelFree = "free";
export const ModelDeveloper = "developer";
export const ModelSingleServer = "developer";

export const FreeTierID = "free";
export const ProTierID = "professional";
export const EnterpriseTierID = "enterprise";
export const PoCTierID = "poc";

export const SupportOrganizationID = "_support";

export const FREE_TRIAL_PERIOD_DAYS = 30;
export const PERPETUAL_FREE_TRIAL_PERIOD_DAYS = 30;
