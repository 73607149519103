//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import styled from "@emotion/styled";
import { isEqual, partition } from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Form, Grid, Header, InputOnChangeData, Checkbox } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { IDOptions as ApiIDOptions, ListOptions as ApiListOptions } from "../../api/common/v1/common";
import { Group as ApiGroup, GroupMemberList as ApiGroupMemberList, GroupMembersRequest as ApiGroupMembersRequest, User as ApiUser } from "../../api/iam/v1/iam";
import { MemberList as ApiMemberList, Organization as ApiOrganization } from "../../api/resourcemanager/v1/resourcemanager";
import { reportError } from "../../errors/reporting";
import { Routes } from "../../routes";
import {
  ContentSegment,
  ErrorMessage,
  FormActionButtonCancel,
  FormActionButtonSave,
  FormContentAction,
  FormContentActions,
  Loading,
  MainContent,
  Processing,
  Section,
  SectionContent,
  SectionHeader,
} from "../../ui/lib";
import { withRefresh, IWithRefreshProps } from "../../util/WithRefresh";
import { HistoryHelper } from "../HistoryHelper";
import { BreadCrumbItem, TopMenuInfo } from "../TopMenuInfo";
import { AvailableMemberListView, ExistingMemberListView, Member } from "./MemberListView";

const FlexBox = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FlexRight = styled(FlexBox)`
  justify-content: flex-end;
`;

const MemberBox = styled("div")`
  position: relative;
`;

interface IUpdateGroupProps extends RouteComponentProps, IWithRefreshProps {
  topMenuInfo: TopMenuInfo;
  organization: ApiOrganization;
  onGroupUpdated: (groupId: string) => void;
}

interface IUpdateGroupState {
  errorMessage?: string;
  groupId?: string;
  group?: ApiGroup;
  groupMembers?: ApiGroupMemberList;
  name: string;
  description: string;
  is_default: boolean;
  organizationMembers?: ApiMemberList;
  users?: ApiUser[];
  selectedMembers: Member[];
  availableUsers: Member[];
  pausedAPICalls: boolean;
  loadingGroupMembers: boolean;
  loadedGroupMembersOnce: boolean;
  processingGroup: boolean;
}

interface IUpdateGroupViewArgs extends IUpdateGroupState, IUpdateGroupProps {
  active?: boolean;
  availableUsers: ApiUser[];
  loadingGroupMembers: boolean;
  handleDismissError: () => void;
  updateFormInputChange: (e: any, args: InputOnChangeData) => void;
  reloadGroupInfo: () => void;
  updateGroup: (routeBack?: boolean) => void;
  addMember: (member: Member) => void;
  removeMember: (member: Member) => void;
  setIsDefault: (val: boolean) => void;
}

const UpdateGroupView = ({ ...args }: IUpdateGroupViewArgs) => {
  const {
    errorMessage,
    name,
    description,
    is_default,
    users = [],
    selectedMembers = [],
    availableUsers = [],
    updateFormInputChange,
    handleDismissError,
    addMember,
    removeMember,
    loadingGroupMembers,
    loadedGroupMembersOnce,
    processingGroup,
    setIsDefault,
  } = args;

  const { group } = args;

  return (
    <MemberBox>
      <ContentSegment>
        <Processing active={processingGroup} message="Updating group, please wait..." />
        <ErrorMessage active={!!errorMessage} onDismiss={handleDismissError} message={errorMessage} />

        <MainContent>
          <Form>
            <Section>
              <SectionHeader title="General" />
              {group ? (
                <SectionContent>
                  <Grid>
                    <Grid.Row columns={16}>
                      <Grid.Column width={4}>
                        <Form.Input autoFocus required label="Name" placeholder="Name" name="name" value={name} onChange={updateFormInputChange} />
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <Form.Input
                          label="Short description"
                          placeholder="Description"
                          name="description"
                          value={description}
                          onChange={updateFormInputChange}
                        />
                      </Grid.Column>
                      <Grid.Column width={2} verticalAlign="bottom"></Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid>
                    <Grid.Row columns={16}>
                      <Grid.Column width={16}>
                        <Checkbox
                          toggle
                          checked={is_default}
                          label="If set, new users are automatically added to this group. If there are multiple default groups, new users are added to all of them"
                          onChange={(e, v) => setIsDefault(!!v.checked)}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </SectionContent>
              ) : (
                !errorMessage && <Loading message="Loading group info" />
              )}
            </Section>
            <Section>
              <SectionHeader
                title={
                  <FlexBox>
                    <Header as="h2">Members </Header>
                  </FlexBox>
                }
                help="This section will help in adding or removing members from group"
              />
              {!loadedGroupMembersOnce ? (
                <Loading message="Loading group members" />
              ) : (
                <SectionContent>
                  <Grid>
                    <Grid.Row columns={16}>
                      <Grid.Column width={8}>
                        <ExistingMemberListView users={users} selectedMembers={selectedMembers} removeMember={removeMember} processing={loadingGroupMembers} />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <AvailableMemberListView availableUsers={availableUsers} addMember={addMember} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </SectionContent>
              )}
            </Section>
            <FlexRight>
              <FormContentActions>
                <FormContentAction>
                  <FormActionButtonSave primary disabled={!users.length} onClick={args.updateGroup} />
                </FormContentAction>
                <FormContentAction>
                  <FormActionButtonCancel disabled={!users.length} onClick={args.history.goBack} />
                </FormContentAction>
              </FormContentActions>
            </FlexRight>
          </Form>
        </MainContent>
      </ContentSegment>
    </MemberBox>
  );
};

class UpdateGroup extends Component<IUpdateGroupProps, IUpdateGroupState> {
  constructor(props: IUpdateGroupProps) {
    super(props);
    this.state = {
      errorMessage: undefined,
      groupId: undefined,
      group: undefined,
      groupMembers: undefined,
      name: "",
      description: "",
      is_default: false,
      organizationMembers: undefined,
      users: undefined,
      selectedMembers: [],
      availableUsers: [],
      pausedAPICalls: false,
      loadingGroupMembers: true,
      loadedGroupMembersOnce: false,
      processingGroup: false,
    };
  }

  updateFormInputChange = (e: any, args: InputOnChangeData) => {
    switch (args.name) {
      case "name":
        this.setState({ name: args.value });
        break;
      case "description":
        this.setState({ description: args.value });
        break;
    }
  };

  setIsDefault = (val: boolean) => {
    this.setState({ is_default: val });
  };

  updateGroup = async (routeBack: boolean = true) => {
    try {
      this.setState({ errorMessage: undefined, processingGroup: true });
      const group: ApiGroup = {
        id: this.state.groupId,
        organization_id: this.props.organization.id,
        name: this.state.name,
        description: this.state.description,
        is_default: this.state.is_default,
      };

      const updatedGroup = await apiClients.iamClient.UpdateGroup(group);
      routeBack && this.props.onGroupUpdated(updatedGroup.id || "");

      const unsavedMembersToAdd = this.state.selectedMembers.filter((member) => member.unsaved);
      const unsavedMembersToRemove = this.state.availableUsers.filter((member) => member.unsaved);

      if (unsavedMembersToAdd.length) {
        let groupMembersRequest: ApiGroupMembersRequest = {
          group_id: updatedGroup.id,
          user_ids: unsavedMembersToAdd.map((member) => member.id) as string[],
        };
        await apiClients.iamClient.AddGroupMembers(groupMembersRequest);
      }

      if (unsavedMembersToRemove.length) {
        let groupMembersRequest: ApiGroupMembersRequest = {
          group_id: updatedGroup.id,
          user_ids: unsavedMembersToRemove.map((member) => member.id) as string[],
        };
        await apiClients.iamClient.DeleteGroupMembers(groupMembersRequest);
      }
      this.setState({ processingGroup: false }, this.getGroupMembers);
    } catch (e) {
      this.setState({ errorMessage: `Group update failed: ${e}` });
      reportError(e);
    }
    this.setState({ processingGroup: false }, () => this.props.history.goBack());
  };

  getGroupInfo = async () => {
    if (this.state.pausedAPICalls) return;

    try {
      const idOptions: ApiIDOptions = { id: this.state.groupId };
      const group = await apiClients.iamClient.GetGroup(idOptions);
      this.setState({ group, name: group.name || "", description: group.description || "", is_default: group.is_default || false });
    } catch (e) {
      this.setState({ errorMessage: e });
      reportError(e);
    }
  };

  getGroupMembers = async () => {
    console.log(" reloading group info");
    if (this.state.pausedAPICalls) return;

    const { users = [] } = this.state;
    this.setState({ loadingGroupMembers: true });
    try {
      const listOptions: ApiListOptions = { context_id: this.state.groupId };

      const groupMembers = await apiClients.iamClient.ListGroupMembers(listOptions);
      const memberList = groupMembers.items || [];

      const [selectedMembers, availableUsers] = partition(users, (user: ApiUser) => memberList.includes(user.id || ""));

      this.setState({ groupMembers: groupMembers, selectedMembers, availableUsers }, () => {
        console.log(" group members loaded and state updated");
        this.setState({ loadingGroupMembers: false, loadedGroupMembersOnce: true });
      });
    } catch (e) {
      this.setState({ errorMessage: e });
      reportError(e);
      this.setState({ loadingGroupMembers: false });
    }
  };

  reloadOrganizationMembers = async () => {
    if (this.state.pausedAPICalls) return;
    const listOptions: ApiListOptions = { context_id: this.props.organization.id };
    const organizationMembers = await apiClients.resourceManagerClient.ListOrganizationMembers(listOptions);
    this.setState({ organizationMembers: organizationMembers });

    const users = [];

    if (organizationMembers.items) {
      for (let index = 0; index < organizationMembers.items.length; index++) {
        const member = organizationMembers.items[index];

        try {
          const idOptions: ApiIDOptions = { id: member.user_id };
          const user = await apiClients.iamClient.GetUser(idOptions);
          users.push(user);
        } catch (e) {
          const user: ApiUser = { id: member.user_id, email: member.user_id };
          users.push(user);
          this.setState({ errorMessage: e });
          reportError(e);
        }
      }
    }
    this.setState({ users: users }, () => {
      const { refreshWithTimer } = this.props;
      const refreshWithTimerCallback = async () => {
        this.getGroupMembers();
      };
      refreshWithTimer && refreshWithTimer(refreshWithTimerCallback, 5000);
    });
  };

  componentDidMount() {
    const groupId = (this.props.match.params as any).groupId;
    this.setState({ groupId: groupId }, () => {
      this.getGroupInfo();
      this.reloadOrganizationMembers();
    });
    this.updateTopMenu();
  }

  handleDismissError = () => {
    this.setState({ errorMessage: undefined });
  };

  componentDidUpdate() {
    this.updateTopMenu();
  }

  updateTopMenu = () => {
    this.props.topMenuInfo.setBackButton(HistoryHelper.getBackButtonInfo(this.props.history));

    const breadCrumb = new Array<BreadCrumbItem>(
      new BreadCrumbItem(this.props.organization.name || "", Routes.dashboard_organization_detailsWithId(this.props.organization.id || "")),
      new BreadCrumbItem("Groups", Routes.dashboard_organization_groupsWithId(this.props.organization.id || ""))
    );
    this.props.topMenuInfo.setBreadCrumbItems(breadCrumb);
    this.props.topMenuInfo.setImageSource("group");
    this.props.topMenuInfo.setTitles(this.state.name || "Update group", "");
  };

  addMember = (member: Member) => {
    const { items = [] } = this.state.groupMembers || {};
    const alreadyAdded = items.includes(member.id || "");

    const availableUsers = this.state.availableUsers.filter((user) => user.id !== member.id);
    const selectedMembers = this.state.selectedMembers.concat({ ...member, unsaved: !alreadyAdded });

    this.setState({ availableUsers: availableUsers, selectedMembers: selectedMembers, pausedAPICalls: true });

    if (isEqual(items.sort(), selectedMembers.map((m) => m.id).sort())) {
      this.setState({ pausedAPICalls: false });
    }
  };

  removeMember = (member: Member) => {
    const { items = [] } = this.state.groupMembers || {};
    const alreadyAdded = items.includes(member.id || "");

    const selectedMembers = this.state.selectedMembers.filter((user) => user.id !== member.id);
    const availableUsers = this.state.availableUsers.concat({ ...member, unsaved: !!alreadyAdded });

    this.setState({ availableUsers: availableUsers, selectedMembers: selectedMembers, pausedAPICalls: true });

    if (isEqual(items.sort(), selectedMembers.map((m) => m.id).sort())) {
      this.setState({ pausedAPICalls: false });
    }
  };

  render() {
    return (
      <UpdateGroupView
        {...this.props}
        {...this.state}
        setIsDefault={this.setIsDefault}
        handleDismissError={this.handleDismissError}
        updateFormInputChange={this.updateFormInputChange}
        updateGroup={this.updateGroup}
        reloadGroupInfo={this.getGroupMembers}
        users={this.state.users}
        availableUsers={this.state.availableUsers}
        selectedMembers={this.state.selectedMembers}
        addMember={this.addMember}
        removeMember={this.removeMember}
        loadingGroupMembers={this.state.loadingGroupMembers}
        active={this.state.processingGroup}
      />
    );
  }
}

export default withRefresh()(UpdateGroup);
