import { Flex, Switch, Text, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { Icon, Popup } from "semantic-ui-react";
import apiClients from "../../api/apiclients";
import { ContentKeyValuePair, ContentKeyValuePairs, ContentKV_Key } from "../../ui/_style";
import { useDeploymentStore } from "../../util/storage/DeploymentStore";
import { useFetchDeployment } from "../editDeployment/useFetchDeployment";

const UseAutoLoginSwitch = () => {
  const toast = useToast();
  const { deployment, setDeployment } = useDeploymentStore();
  const isPlatformAuthEnabled = !!deployment.is_platform_authentication_enabled;
  const { refetch: refetchDeployment } = useFetchDeployment({ deploymentId: deployment.id || "" });
  const [isUpdatingPlatformAuthEnabled, setIsUpdatingPlatformAuthEnabled] = useState<boolean>(false);

  const updateIsPlatformAuthEnabled = async (enabled: boolean) => {
    setIsUpdatingPlatformAuthEnabled(true);
    try {
      await apiClients.dataClient.UpdateDeployment({
        ...deployment,
        is_platform_authentication_enabled: enabled,
      });

      const { data: newDeployment } = await refetchDeployment();

      setDeployment(newDeployment || {});
    } catch (error) {
      toast({
        title: "Failed to update platform auth",
        description: error.message ? error.message : undefined,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsUpdatingPlatformAuthEnabled(false);
    }
  };

  return (
    <ContentKeyValuePairs>
      <ContentKeyValuePair>
        <Flex alignItems="center" gap={2}>
          <ContentKV_Key>
            <Flex alignItems="baseline" gap={1}>
              <Text>Use auto login for database UI</Text>
              <Popup
                position="top center"
                trigger={<Icon name="info circle" />}
                content={
                  isPlatformAuthEnabled
                    ? "Disabling this will require you to enter username and password to access the database UI"
                    : "Enabling this will allow you to automatically log in to the database UI with your current ArangoGraph platform user. If you switch off the auto login, you can use the root user to access the database UI instead."
                }
              />
            </Flex>
          </ContentKV_Key>
          <Switch
            isChecked={isPlatformAuthEnabled}
            isDisabled={isUpdatingPlatformAuthEnabled}
            onChange={() => {
              updateIsPlatformAuthEnabled(!isPlatformAuthEnabled);
            }}
          />
        </Flex>
      </ContentKeyValuePair>
    </ContentKeyValuePairs>
  );
};

export { UseAutoLoginSwitch };
