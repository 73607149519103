//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import React from "react";
import { NavLink } from "react-router-dom";
import { Icon, Modal, Table } from "semantic-ui-react";
import _ from "lodash";
import { Currency as ApiCurrency, DeploymentPrice as ApiDeploymentPrice } from "../../api/lib";
import { Routes } from "../../routes";
import { formatUnroundedPrice as formatPrice } from "../../util/Price";
import { useDashboardContext } from "../DashboardContextProvider";
import { FREE_TRIAL_PERIOD_DAYS, PERPETUAL_FREE_TRIAL_PERIOD_DAYS } from "../../constants";

interface IPriceModelViewArgs {
  trigger: React.ReactNode;
  price?: ApiDeploymentPrice;
  currency?: ApiCurrency;
  is_free_tier: boolean;
  organization_id: string;
  supports_auditlog: boolean;
  isPrepaidDeployment: boolean;
  showCreditsPrice?: boolean;
}

const PriceModelView = ({ ...args }: IPriceModelViewArgs) => {
  const { isPerpetualFreeTrialAvailable } = useDashboardContext();

  const price = args.price || {};
  const has_price = !!args.price;
  const backup_price = price.backup_price || {};
  const auditlog_price = price.auditlog_price || {};
  const network_transfer_prices = price.network_transfer_prices || [];
  const currency = args.currency || {};
  const supports_auditlog = !!args.supports_auditlog;
  const billingRoute = Routes.dashboard_organization_billingWithId(args.organization_id);
  const isPrepaidDeployment = args.isPrepaidDeployment;
  if (!has_price) {
    return <span>{args.trigger}</span>;
  }
  return (
    <Modal trigger={args.trigger}>
      <Modal.Header>Pricing explained</Modal.Header>
      <Modal.Content>
        {args.is_free_tier && (
          <div>
            <h3 className="heading-3">
              <Icon name="gift" className="secondary-text" /> Free trial
            </h3>
            {isPerpetualFreeTrialAvailable && (
              <p className="para">
                You're currently in a free trial, there is no need to enter a payment method and you will not be billed for any usage. You can create one
                free-to-try deployment with a {PERPETUAL_FREE_TRIAL_PERIOD_DAYS} days trial period.
              </p>
            )}
            {!isPerpetualFreeTrialAvailable && (
              <p className="para">
                You're currently in a free trial, there is no need to enter a payment method and you will not be billed for any usage. You can create one
                free-to-try deployment with a {FREE_TRIAL_PERIOD_DAYS} days trial period.{" "}
              </p>
            )}
            <p>
              To unlock all ArangoGraph functionality,{" "}
              <NavLink to={billingRoute} className="text-link">
                enter billing information
              </NavLink>{" "}
              for your organization and add a payment method.
            </p>
            <p className="para">The pricing information below shows what you'll pay after adding a payment method.</p>
          </div>
        )}
        <h3 className="heading-3">
          <Icon name="database" className="secondary-text" /> Deployment price
        </h3>
        {isPrepaidDeployment && <p className="para">The deployment is prepaid as part of your contract.</p>}
        {!isPrepaidDeployment && <p className="para">The deployment is priced per hour.</p>}
        {!isPrepaidDeployment && (
          <Table basic size="small">
            <Table.Row>
              <Table.Cell collapsing width="6">
                Price per hour:
              </Table.Cell>
              <Table.Cell>{formatPrice(price.price_per_hour || 0, args.showCreditsPrice ? "credits" : currency.sign || "", "")}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell collapsing width="6">
                Price per month:
              </Table.Cell>
              <Table.Cell>{formatPrice(730 * (price.price_per_hour || 0), args.showCreditsPrice ? "credits" : currency.sign || "", "")}</Table.Cell>
            </Table.Row>
          </Table>
        )}
        <h3 className="heading-3">
          <Icon name="at" className="secondary-text" /> Network pricing
        </h3>
        <p className="para">Network transfer in/out of the deployment is priced per GB of data.</p>
        <Table basic size="small">
          <Table.Body
            children={_.flattenDeep(
              network_transfer_prices.map((x, i) => [
                <Table.Row>
                  <Table.Cell collapsing width="6">
                    {x.description} ingress:
                  </Table.Cell>
                  <Table.Cell>{formatPrice(x.ingress_price_per_gb || 0, currency.sign || "", "GB")}</Table.Cell>
                </Table.Row>,
                <Table.Row>
                  <Table.Cell collapsing width="6">
                    {x.description} egress:
                  </Table.Cell>
                  <Table.Cell>{formatPrice(x.egress_price_per_gb || 0, currency.sign || "", "GB")}</Table.Cell>
                </Table.Row>,
              ])
            )}
          />
        </Table>
        <h3 className="heading-3">
          <Icon name="disk" className="secondary-text" /> Backup pricing
        </h3>
        <p className="para">Uploaded backups are priced per GB of storage per hour.</p>
        <Table basic size="small">
          <Table.Row>
            <Table.Cell collapsing width="6">
              Price per hour:
            </Table.Cell>
            <Table.Cell>{formatPrice(backup_price.price_per_gb_per_hour || 0, currency.sign || "", "GB")}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing width="6">
              Price per month:
            </Table.Cell>
            <Table.Cell>{formatPrice(730 * (backup_price.price_per_gb_per_hour || 0), currency.sign || "", "GB")}</Table.Cell>
          </Table.Row>
        </Table>
        {supports_auditlog && (
          <div>
            <h3 className="heading-3">
              <Icon name="play" className="secondary-text" /> Audit Log pricing
            </h3>
            <p className="para">AuditLog (destination: cloud) are priced per GB of storage per hour.</p>
            <Table basic size="small">
              <Table.Row>
                <Table.Cell collapsing width="6">
                  Price per hour:
                </Table.Cell>
                <Table.Cell>{formatPrice(auditlog_price.price_per_gb_per_hour || 0, currency.sign || "", "GB")}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing width="6">
                  Price per month:
                </Table.Cell>
                <Table.Cell>{formatPrice(730 * (auditlog_price.price_per_gb_per_hour || 0), currency.sign || "", "GB")}</Table.Cell>
              </Table.Row>
            </Table>
            <p className="para">AuditLog (destination: https-post) are priced per endpoint invocation and transmitted eventlogs per GB of data.</p>
            <Table basic size="small">
              <Table.Row>
                <Table.Cell collapsing width="6">
                  Price for invocations:
                </Table.Cell>
                <Table.Cell>{formatPrice(auditlog_price.https_post_invocation_price_per_1000 || 0, currency.sign || "", "1.000")}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing width="6">
                  Price for transmitted data:
                </Table.Cell>
                <Table.Cell>{formatPrice(auditlog_price.https_post_body_size_price_per_gb || 0, currency.sign || "", "GB")}</Table.Cell>
              </Table.Row>
            </Table>
          </div>
        )}
        <h3 className="heading-3">
          <Icon name="calendar" className="secondary-text" /> Billing cycle
        </h3>
        {isPrepaidDeployment && <p className="para">This is specified in your contract.</p>}
        {!isPrepaidDeployment && (
          <p className="para">
            Once you enter your payment information, you will be charged at least once a month. If your usage exceeds a certain threshold, you will be charged
            earlier (up to once a week).
          </p>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default PriceModelView;
