//
// Copyright ArangoDB GmbH, Cologne, Germany
// All rights reserved. See LICENSE.md in the project root for license information.
//

import _ from "lodash";
import React, { Component } from "react";
import styled from "@emotion/styled";
import { Form, Grid, Message } from "semantic-ui-react";
import { PreparedPaymentMethod as ApiPreparedPaymentMethod, CreatePaymentMethodRequest as ApiCreatePaymentMethodRequest } from "../../api/lib";
import Script from "react-load-script";
import { reportError } from "../../errors/reporting";

const StyledHostedPaymentFields = styled("div")`
  iframe {
    max-height: 3em !important;
  }
`;

const createBluesnapCurrency = (currency_id: string) => {
  switch (currency_id) {
    case "euro":
      return "EUR";
    case "usd":
      return "USD";
    default:
      return currency_id.toUpperCase();
  }
};

// Interface decribing the properties of the bluesnap creditcard component
interface IBluesnapCreditCardProps {
  debugLog: boolean;
  preparedPaymentMethod: ApiPreparedPaymentMethod;
  onCreate: (req: ApiCreatePaymentMethodRequest) => void;
  onCanCreate: (canCreate: boolean) => void;
  onSaveCanceled: () => void;
}

// Interface decribing the state of the bluesnap creditcard component
interface IBluesnapCreditCardState {
  errorMessage?: string;
  scriptLoaded: boolean;
  bluesnapReady: boolean;
  preparedPaymentMethod: ApiPreparedPaymentMethod;
  cardType?: string;
}

class BluesnapCreditCard extends Component<IBluesnapCreditCardProps, IBluesnapCreditCardState> {
  state = {
    errorMessage: undefined,
    scriptLoaded: false,
    bluesnapReady: false,
    preparedPaymentMethod: this.props.preparedPaymentMethod,
  } as IBluesnapCreditCardState;

  static getDerivedStateFromProps(props: IBluesnapCreditCardProps, state: IBluesnapCreditCardState) {
    if (
      props.preparedPaymentMethod.provider_id != state.preparedPaymentMethod.provider_id ||
      props.preparedPaymentMethod.token != state.preparedPaymentMethod.token ||
      props.preparedPaymentMethod.signature != state.preparedPaymentMethod.signature
    ) {
      props.debugLog && console.log("ppm changed");
      return {
        preparedPaymentMethod: props.preparedPaymentMethod,
        loading: false,
        scriptLoaded: false,
        bluesnapReady: false,
        errorMessage: undefined,
      };
    }
    // No state update necessary
    return null;
  }

  updateCanCreate = () => {
    this.props.onCanCreate(this.state.bluesnapReady && !this.state.errorMessage);
  };

  onScriptError = () => {
    this.setState(
      {
        errorMessage: "Script failed to load",
        scriptLoaded: false,
        bluesnapReady: false,
      },
      this.updateCanCreate
    );
  };
  onScriptCreate = () => {
    this.setState(
      {
        scriptLoaded: false,
        bluesnapReady: false,
      },
      this.updateCanCreate
    );
  };
  onScriptLoad = () => {
    this.setState(
      {
        scriptLoaded: false,
        bluesnapReady: false,
      },
      () => {
        this.updateCanCreate();
        const token = this.state.preparedPaymentMethod.token;
        const bluesnap = (window as any)["bluesnap"];
        if (!!token && !!bluesnap) {
          this.prepareBluesnap(token, bluesnap);
        }
      }
    );
  };

  prepareBluesnap = (token: string, bluesnap: any) => {
    const comp = this;
    const debugLog = this.props.debugLog;
    try {
      const bsObj = {
        "3DS": true,
        token: token,
        onFieldEventHandler: {
          /*OPTIONAL*/ setupComplete: function () {
            debugLog && console.log("setupComplete");
            debugLog && console.log(`token (a): ${token}`);
            // Report state change
            comp.setState(
              {
                scriptLoaded: true,
                bluesnapReady: true,
              },
              comp.updateCanCreate
            );
          },
          /*OPTIONAL*/ threeDsChallengeExecuted: function () {
            debugLog && console.warn("threeDsChallengeExecuted");
          },
          // tagId returns: "ccn", "cvv", "exp"
          onFocus: function (tagId: any) {
            comp.updateCanCreate();
          }, // Handle focus
          onBlur: function (tagId: any) {
            comp.updateCanCreate();
          }, // Handle blur
          onError: function (tagId: any, errorCode: any /*, errorDescription*/) {
            debugLog && console.log(`onError(${tagId}, ${errorCode})`);
            let errorMessage = "Invalid creditcard";
            switch (_.toString(errorCode)) {
              case "10":
                if (tagId == "cvv") {
                  errorMessage = "Invalid CVV";
                } else if (tagId == "exp") {
                  errorMessage = "Invalid expiration date";
                }
                break;
              case "22013":
                errorMessage = "Credit card type is not support";
                break;
              case "14100":
                errorMessage = "3-D Secure is not enabled";
                break;
              case "14101":
                errorMessage = "3-D Secure authentication failed";
                break;
              case "14102":
                errorMessage = "3-D Secure object is missing required fields";
                break;
              case "14103":
                errorMessage = "3-D Secure client error";
                break;
              case "14040":
                errorMessage = "Token is expired. Please refresh and try again";
                break;
              case "14041":
                errorMessage = "Invalid token. Please refresh and try again";
                break;
              case "14042":
                errorMessage = "Token is not associated. Please refresh and try again";
                break;
              case "400":
                errorMessage = "Session expired. Please refresh and try again";
                break;
              case "403":
              case "404":
              case "500":
                errorMessage = "Internal server error. Please refresh and try again later";
                break;
            }
            comp.setState({ errorMessage: errorMessage }, comp.updateCanCreate);
            comp.props.onSaveCanceled();
          }, // Handle a change in validation
          /*errorCode returns:
                            "10" --> invalidCcNumber, invalidExpDate, invalidCvv Dependent on the tagId;
                            "22013" --> "CC type is not supported by the merchant"; 
                            "14040" --> " Token is expired";
                            "14041" --> " Could not find token";
                            "14042" --> " Token is not associated with a payment method, please verify your client integration or contact BlueSnap support";
                            "400" --> "Session expired please refresh page to continue";
                            "403", "404", "500" --> "Internal server error please try again later"; 
                            14100,  Problem: 3-D Secure is not enabled
                                    Resolution: Enable 3-D Secure
                            14101   Problem: 3-D Secure authentication failed
                                    Resolution: Inform the shopper to try a different payment method
                            14102   Problem: 3-D Secure object is missing required fields { ... }
                                    Resolution: Make sure 3-D Secure object has both transaction amount and currency
                            14103   Problem: 3-D Secure client error
                                    Resolution: Continue without 3DS
                        */

          /* errorDescription is optional. Returns BlueSnap's standard error description */

          onType: function (tagId: any, cardType: any /*, cardData*/) {
            /* cardType will give card type, and only applies to ccn: AMEX, VISA, MASTERCARD, AMEX, DISCOVER, DINERS, JCB */
            if (null != cardType) {
              /* cardData is an optional parameter which will provide ccType, last4Digits, issuingCountry, isRegulatedCard, cardSubType, binCategory and ccBin details (only applies to ccn) in a JsonObject */
              debugLog && console.log(cardType);
              if (cardType == "UNKNOWN") {
                cardType = undefined;
              }
              comp.setState({ cardType: cardType });
              comp.updateCanCreate();
            }
          },

          onValid: function (tagId: any) {
            debugLog && console.log(`OnValid: ${tagId}`);
            comp.setState({ errorMessage: undefined });
            comp.updateCanCreate();
          }, // Handle a change in validation
        },
        /* example:
                        style: {
                        "Selector": {
                        "Property": "Value",
                        "Property2": "Value2"
                        },                                                                                                                                                             
                        "Selector2": {
                        "Property": "Value"
                        } 
                    }, */
        style: {
          ":focus": {
            //style for all input elements on focus event
            color: "red",
          },
          input: {
            //style for all input elements
            color: "blue",
            "background-color": "white",
            height: "auto !important",
            "border-bottom": "solid 1px silver",
            "font-size": "14px",
            "line-height": "18px",
            padding: "11px 14px",
          },
          ".invalid": {
            //style for all input elements when invalid
            color: "red",
          },
        },
        ccnPlaceHolder: "1234 5678 9012 3456", //for example
        cvvPlaceHolder: "123", //for example
        expPlaceHolder: "MM/YY", //for example
      };

      // Run the following command after Document Object Model (DOM) is fully loaded
      bluesnap.hostedPaymentFieldsCreate(bsObj);
    } catch (e) {
      debugLog && console.log(e);
      reportError(e);
      this.updateCanCreate();
    }
  };

  onClickSave = () => {
    const debugLog = this.props.debugLog;
    debugLog && console.log("onClickSave");
    debugLog && console.log(`token (b): ${this.state.preparedPaymentMethod.token}`);
    if (!this.state.bluesnapReady) {
      debugLog && console.log("bluesnap not yet ready");
      throw "Bluesnap not yet ready";
    }
    const bluesnap = (window as any)["bluesnap"];
    if (!bluesnap) {
      debugLog && console.log("bluesnap object not available");
      throw "Bluesnap script not available";
    }
    debugLog && console.log("calling hostedPaymentFieldsSubmitData");
    const threeDSecureObj = {
      amount: 0.0,
      currency: createBluesnapCurrency(this.state.preparedPaymentMethod.currency_id || "usd"),
    };
    debugLog && console.log("threeDSecureObj: ", threeDSecureObj);
    bluesnap.hostedPaymentFieldsSubmitData((callback: any) => {
      console.log("result: ");
      console.log(callback);
      if (null != callback.cardData) {
        console.log(
          "card type: " +
            callback.cardData.ccType +
            ", last 4 digits: " +
            callback.cardData.last4Digits +
            ", exp: " +
            callback.cardData.exp +
            ", issuing Country: " +
            callback.cardData.issuingCountry +
            ", isRegulatedCard: " +
            callback.cardData.isRegulatedCard +
            ", cardSubType: " +
            callback.cardData.cardSubType +
            ", binCategory: " +
            callback.cardData.binCategory +
            " and ccBin: " +
            callback.cardData.ccBin +
            ", and 3D Secure result: " +
            (callback.threeDSecure != null ? callback.threeDSecure.authResult : null)
        );
        // submit the form

        debugLog && console.log(`token (c): ${this.state.preparedPaymentMethod.token}`);
        const req = {
          prepared_payment_method: this.state.preparedPaymentMethod,
          issuing_country: callback.cardData.issuingCountry,
        } as ApiCreatePaymentMethodRequest;
        this.props.onCreate(req);
      } else {
        console.log("Callback error:");
        var errorArray = callback.error;
        console.log(errorArray);
        /*for (i: any in errorArray) {
                console.log("Received error: tagId= " +
                    errorArray[i].tagId + ", errorCode= " +
                    errorArray[i].errorCode + ", errorDescription= " +
                    errorArray[i].errorDescription);
                }*/
      }
    }, threeDSecureObj);
    debugLog && console.log("called hostedPaymentFieldsSubmitData");
  };

  render() {
    const signature = this.state.preparedPaymentMethod.signature || "";
    const script_url = this.state.preparedPaymentMethod.script_url;
    const has_script_url = !!script_url;
    const has_cardType = !!this.state.cardType;
    const cardType = this.state.cardType || "";
    const has_errorMessage = !!this.state.errorMessage;
    return (
      <StyledHostedPaymentFields>
        {has_script_url && (
          <Script key={`key-${signature}`} url={script_url} onCreated={this.onScriptCreate} onError={this.onScriptError} onLoad={this.onScriptLoad} />
        )}
        <Grid width="16">
          <Grid.Row>
            <Grid.Column width="15">
              <Form.Field>
                <label>Card number</label>
                <div data-bluesnap="ccn"></div>
              </Form.Field>
            </Grid.Column>
            <Grid.Column width="1">
              {has_cardType && (
                <img
                  style={{ width: "40px", height: "25px" }}
                  src={`https://ws.bluesnap.com/source/web-sdk/hosted-payment-fields/cc-types/${_.toLower(cardType)}.png`}
                  alt={cardType}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">
              <Form.Field>
                <label>Expiration (MM/YY)</label>
                <div data-bluesnap="exp"></div>
              </Form.Field>
            </Grid.Column>
            <Grid.Column width="8">
              <Form.Field>
                <label>CVV</label>
                <div data-bluesnap="cvv"></div>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          {has_errorMessage && (
            <Grid.Row>
              <Grid.Column width="16">
                <Message content={_.toString(this.state.errorMessage)} error />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </StyledHostedPaymentFields>
    );
  }
}

export default BluesnapCreditCard;
